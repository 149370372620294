import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

export default function AddStaking(address) {
  const { formatAddress, formatDateTime } = useContext(GMCContext);
  const [u_address, setu_address] = useState("");
  const [ref_address, setref_address] = useState("");
  const [amount, setamount] = useState(50);
  const [sleg, setsleg] = useState("left");
  const [error, seterror] = useState(null);
  const [search, setSearch] = useState("");
  const onDeposit = async () => {
    if (!u_address) {
      seterror("Enter Address");
      return;
    }
    if (!ref_address) {
      seterror("Enter Referral Address");
      return;
    }
    if (!amount || amount < 0) {
      seterror("Enter amount");
      return;
    }

    await axios
    .post(process.env.REACT_APP_BACKEND_LINK, {
      method: "transaction",
      submethod: "insert0x",
      key: process.env.REACT_APP_KEY,
      address: u_address,
      ref_address: ref_address,
      amount: amount,
      leg: sleg === "left" ? 0 : 1,
    })
    .then((res) => {
      // var dedata = decryptData(res.data);
      if (res.data.error) {
        seterror(res.data.error);
        return "";
      }
      seterror("Transaction done");
    });
  }
  useEffect(()=>{
console.log("am",amount,sleg);

  },[amount,sleg])
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Add Transaction
                </li>
              </ol>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-body p-0">
                    <h3 class="py-3 px-5">Add Transaction</h3>
                    <hr />
                    <div className="auth-form">
                      <div className="form-group">
                        <label className="form-label">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={u_address}
                          onChange={(e) => setu_address(e.target.value)}
                        />
                      </div>
                      <label className="form-label">Referral Address</label>
                      <div className="mb-3 position-relative">
                        <input
                          type="text"
                          id="referral"
                          className="form-control"
                          value={ref_address}
                          onChange={(e) => setref_address(e.target.value)}

                        />
                      </div>
                      <div className="form-group mb-4">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            id="inlineRadio2"
                            type="radio"
                            name="leg"
                            value="left"
                            checked={sleg === 'left'}
                            defaultChecked={true}
                            onChange={(e) =>
                              setsleg(
                                e.target.value
                              )
                            }

                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Left
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            id="inlineRadio3"
                            type="radio"
                            name="leg"
                            value="right"
                            checked={sleg === 'right'}
                            onChange={(e) =>
                              setsleg(
                                e.target.value
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            Right
                          </label>
                        </div>
                      </div>

                      <label className="form-label">Bot Amount</label>
                      <div className="mb-3 position-relative">
                        <select onChange={(e) => setamount(e.target.value)}>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={200}>200</option>
                          <option value={500}>500</option>
                        </select>
                      </div>
                      {error ? (
                        <div class="alert alert-danger solid alert-square ">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          onClick={() => onDeposit()}
                        >
                          Add Transaction
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
